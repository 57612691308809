@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&family=Outfit:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moderustic:wght@300..800&display=swap');

:root {
  --theme: #04CE78;
  --theme2: #1F5FFF;
  --title: #000D44;
  --body: #788094;
  --smoke: #F5F7FA;
  --smoke2: #F5F8FD;
  --black: #000000;
  --gray: #bdbdbd;
  --white: #ffffff;
  --light: #bdbdbd;
  --yellow: #FFB539;
  --success: #28a745;
  --error: #dc3545;
  --th-border: #D8DDE1;

  --vrts:#26935A;


  --f1: 'Outfit', sans-serif;     /* --title-font */
  --f2: 'DM Sans', sans-serif;    /* --body-font */
  --f3: "Moderustic", sans-serif; /* --MenuX */
  
  --s1:14px;
  --s2:16px;
  --s3:18px;
  --s4:20px;
  --s5:24px;
  --s6:48px;
  --s7:84px;

  --w1:400;
  --w2:500;
  --w3:600;
  --w4:700;

  --icon-font: "Font Awesome 6 Pro";
  --main-container: 1224px;
  --container-gutters: 24px;
  --section-space: 120px;
  --section-space-mobile: 80px;
  --section-title-space: 60px;
  --ripple-ani-duration: 5s;
}

/* =============================================================================================== */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
em{
  font-style: normal;
}
#root{
  width: 100%;
  height: auto;
  overflow-x: hidden;
}
.wrapper{
  width: 90%;
  margin-left: 5%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.show{
  display: flex !important;
}
.hide{
  display: none !important;
}
.Btn{
  padding: 1rem 1.5rem;
  font-size: var(--s1);
  font-family: var(--f2);
  font-weight: var(--w4);
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
  transition: .5s all;
  cursor: pointer;
  text-decoration: none;
}
.Btn:hover{
  transform: translateY(-5px);
}
.themeBtn{
  background-color: var(--theme);
  color: var(--title);
}
.theme2Btn{
  background-color: var(--theme2);
  color: var(--white);
}

/* Home ======================================================================*/
.sectionTopic{
  font-size: var(--s6);
  font-family: var(--f1);
  font-weight: var(--w4);
  color: var(--title);
  line-height: var(--s6);
  margin-bottom: 1rem;
}
.sectionContent{
  font-size: var(--s2);
  font-family: var(--f2);
  font-weight: var(--w1);
  color: var(--body);
  line-height: var(--s5);
  margin-bottom: 1rem;
}
.subTitle{
  font-size: var(--s4);
  font-family: var(--f1);
  font-weight: var(--w2);
  color: var(--title);
  line-height: var(--s6);
  /* margin-bottom: 1rem; */
}
.serviceCardTitle{
  font-size: var(--s5);
  font-family: var(--f1);
  font-weight: var(--w3);
  color: var(--title);
  line-height: var(--s6);
  margin-bottom: 1rem; 
}
@media screen and (max-width:768px) {
  .sectionTopic{
    text-align: center;
  }
  .sectionContent{
    text-align: center;
  }
  .subTitle{
    line-height: var(--s4);
  }
}