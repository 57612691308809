.title{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: .5rem;    
    margin-bottom: .5rem;
}
.title img{
    width: 2rem;
}
.title h5{
    font-size: var(--s3);
    font-family: var(--f1);
    font-weight: var(--w2);
    color: var(--theme);
    line-height: 28px;
    text-transform: uppercase;
}

/* ========================== Testimonial =================================================== */
.homeTestimonail{
    width: 100%;
    padding: 5%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.homeTestimonail main{
    width: 100%;
    overflow: hidden;
    margin: 2rem 0;
}
.slider{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 2%;
    transform: translateX(-0%);
    padding:0 5%;
    transition: 2s all;
}
.slide{
    width: 45%;
    background-color: var(--smoke2);
    padding: 1.5rem;
    border-radius: 30px;
}
.slide figure{
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: flex-start;
    gap: .25rem;
}
.slide figure img{
    width: 25px;
}
.slide aside{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.slide aside h3{
    font-size: var(--s4);
    font-family: var(--f1);
    font-weight: var(--w2);
    color: var(--title);
    line-height: var(--s5);
}
.slide aside h4{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--body);
    line-height: var(--s3);
}
.testContent{
    min-height: 170px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
@media screen and (max-width:768px) {
    .slide{
        width: 100%;
    }
    .testContent{
        text-align: left;
    }
}

/* ======================== About ============================================================= */
.homeAbout{
    width: 100%;
    padding: 10%;
    padding-bottom: 5%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    z-index: 1;
}
.homeAbout figure{
    width: 50%;
    height: 500px;
    /* background-color: var(--theme); */
    position: relative;
    z-index: 1;
}
.homeAboutImage{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 25px;
}
.homeAbout main{
    width: 50%;
    padding-left: 5%;
    padding-right: 1rem;
    z-index: 1;
}
.homeAboutContent{
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.homeAbout main ul{
    column-count: 2;
    padding: 1rem;
    margin-bottom: 2rem;
}
.homeAbout main ul li{
    /* list-style: none; */
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--title);
    line-height: var(--s2);
    margin-bottom: 1rem;
}
.homeAboutBg{
    position: absolute;
    top: 0;
    right: 0;
    z-index: 0;
}
.homeAboutMedicine{
    position: absolute;
    bottom: 5%;
    right: 5%;
    width: 200px;
    animation: shape5 6s infinite linear;
}
.homeAboutMedicine img{
    width: 100%;
    object-fit: contain;
}
@media screen and (max-width:768px) {
    .homeAbout{
        flex-direction: column;
        padding: 1rem;
    }
    .homeAbout figure{
        width: 100%;
        height: auto;
        aspect-ratio: 1/1;
        margin-bottom: 2rem;
    }
    .homeAbout main{
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .homeAbout main ul{
        column-count: 1;
    }
}
/* ============================= Bend Boarder ==================================== */
.topLeft{
    width: 34.5%;
    height: 35%;
    min-width: 150px;
    min-height: 150px;
    background-color: var(--white);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
    border-bottom-right-radius: 55px;
    border-top-left-radius: 0;

    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}
.topLeft::before{
    content: "";
    position: absolute;
    bottom: -30px;
    left: 0px;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    background-color: transparent;
    border-radius: 50%;
    box-shadow: -15px -15px 0 var(--white);
}
.topLeft::after{
    content: "";
    position: absolute;
    top: 0px;
    right: -32px;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    /* background-color: transparent; */
    border-radius: 50%;
    box-shadow: -15px -15px 0 var(--white);
}
.bottomRight{
    width: 40%;
    height: 40%;
    min-width: 200px;
    min-height: 200px;
    background-color: var(--white);
    position: absolute;
    bottom: -0.25px;
    right: -1px;
    border-radius: 15px;
    border-top-left-radius: 55px;
    border-bottom-right-radius: 0;

    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.bottomRight::before{
    content: "";
    position: absolute;
    bottom: .5px;
    left: -31px;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 15px 15px 0 var(--white);
}
.bottomRight::after{
    content: "";
    position: absolute;
    top: -30px;
    right: 0px;
    width: 30px;
    height: 30px;
    /* background-color: blue; */
    background-color: transparent;
    border-radius: 50%;
    box-shadow: 15px 15px 0 var(--white);
}
.topLeft img{
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    object-fit: cover;
    border-radius: 20px;
    border-bottom-right-radius: 48px;
}
.bottomRight aside{
    width: calc(100% - 15px);
    height: calc(100% - 15px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
    border-radius: 20px;
    border-top-left-radius: 50px;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 1.5rem;
}
.bottomRight aside p{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--body);
    line-height: var(--s3);
    margin-bottom: 1rem;
    text-align: center;
}
.bottomRight aside ul li{
    font-size: var(--s2);
    font-family: var(--f1);
    font-weight: var(--w2);
    color: var(--title);
    line-height: var(--s3);
    /* margin-bottom: 1rem; */
    text-align: center;
}

/* ======== HomeEnq ====================================================== */
.homeEnq{
    width: 85%;
    margin-left: 7.5%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
}
.timingContainer{
    width: 32%;
    padding: 2rem 1rem;
    position: relative;
}
.timing{
    width: 100%;
    background-color: var(--theme2);
    padding: 2rem;
    border-radius: 25px;
    z-index: 1;
    position: relative;
}
.timing span{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 1.5rem 0;
    border-bottom: 1px solid var(--white);
}
.timing span:last-child{
    border-bottom: none;
    padding-bottom: 0rem;
}
.timing span h3{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w2);
    color: var(--white);
    line-height: var(--s3);
}
.enqtitle{
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    font-family: var(--f2);
    font-weight: var(--w3);
    line-height: 30px;
    margin-bottom: 2rem;
    text-align: center;
}
.white{
    color: var(--white);
}
.title{
    color: var(--title);
}
.formContainer{
    width: 65%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2.5rem;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 30px;
}
.formPic{
    width: 45%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 2rem;
}
.formPic img{
    width: 100%;
    object-fit: cover;
    aspect-ratio: 4/3;
    border-radius: 25px;
}
.formPic img:nth-child(2){
    object-position: center;
    /* transform: translateY(10px); */
}
.formContainer form{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.inputContainer{
    width: 100%;
    margin-bottom: 1.25rem;
}
.inputContainer input{
    width: 100%;
    padding: 1rem 1rem;
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w2);
    color: var(--title);
    line-height: var(--s3);
    background-color: var(--smoke);
    border-radius: 50px;    
    border: none;
    outline: none;
    padding-left: 1.5rem;
}
.inputContainer input:focus{
    border: 1px solid var(--gray);
}
.formRow{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}
.inputContainer button{
    width: 100%;
}
.enqBG{
    position: absolute;
    width: 80%;
    height: 100%;
    border: 3px solid var(--gray);
    border-radius: 25px;
    top: 0;
    left: -15%;
    z-index: 0;
}
.enqShape{
    width: 100px;
    height: 100px;
    background-color: var(--theme);
    position: absolute;
    top: 2%;
    left: -8%;
    border-radius: 25px;
    transform: translateY(0);
    animation: yMove 2s infinite linear;
}

@keyframes yMove {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(20%);
    }
    100%{
        transform: translateY(0);
    }
}
@media screen and (max-width:768px) {
    .enqBG, .enqShape{
        display: none;
    }
    .homeEnq{
        flex-direction: column;
        width: 90%;
        margin-left: 5%;
    }
    .timingContainer{
        width: 100%;
    }
    .formContainer{
        width: 100%;
        flex-direction: column;
        align-items: flex-start;
        padding: 0;
        margin-top: 2rem;
    }
    .formPic{
        width: 100%;
        flex-direction: row;
    }
    .formPic img{
        width: 45%;
    }
    .formContainer form{
        width: 100%;
        margin-top: 2rem;
    }
}

/* ========================= Service Card =============================================================== */
.serviceCard{
    width: 300px;
    height: 300px;
    padding: 2rem;
    /* box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}
.serviceCard figure{
    width: 70px;
    aspect-ratio: 1/1;
    background-color: #e6e9ee;
    padding: .25rem;
    border-radius: 50%;
    margin-bottom: 2rem;

    display: flex;
    justify-content: center;
    align-items: center;
}
.serviceCard figure img{
    width: 80%;
}
.cardTitleText{
    line-height: var(--s5);
    text-align: center;
}