.aboutWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10rem;    
}
.aboutWrapper h2{    
    width: 70%;
    text-align: center;
}
.aboutLanding{    
    background-image: url("../../public/images/breadcumb-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.aboutBanner{    
    background-image: url("../../public/images/breadcumb-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.aboutService h2{
    width: 70%;
    text-align: center;
    margin: 1rem 0;
}
.aboutBanner{
    align-items: flex-start;
    padding: 7rem;
    padding-left: 5rem;
    max-height: 500px;
}
.aboutBanner h2{
    width: 50%;
    text-align: left;
}
.aboutBanner p{
    width: 40%;
    text-align: left;
}
.aboutBanner span{
    color: var(--theme);
}
.aboutBanner aside{
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    gap: 5rem;
}
@media screen and (max-width:768px) {
    .aboutWrapper{
        padding: 10rem 1rem;
    }
    .aboutWrapper h2{  
        width: 100%;
    }
    .aboutBanner aside{
        flex-direction: column;
        gap: 1rem;
    }
}