.footer{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 4rem 6rem;
    gap: 5%;
    background-color: var(--vrts);
    flex-wrap: wrap;

    background-image: url("../../public/images/footerBG.jpeg");
    background-blend-mode: color-dodge;
}
.footerMain, .footerContact{
    width: 20%;
    min-width: 350px;
}
.footerMain figure{
    width: 100%;
    margin-bottom: 2rem;
}
.footerMain figure img{
    width: 100%;
}
.footerMain h3, .footerMain p{
    color: var(--white);
    text-align: left;
    margin: 0;
}
.footerContact h4{
    line-height: var(--s4);
}
.footerContact h3{
    margin-bottom: 2rem;
    padding-bottom: .5rem;
}
.footerUnderline{
    position: relative;
}
.footerUnderline::after{
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20%;
    height: 3px;
    background-color: var(--white);
}
.footerMenu, .footerWrite{
    width: 15%;
} 
.footerMenu ul{
    padding: 1rem 0;
    list-style: none;
}
.footerMenu ul li{
    position: relative;
    padding-left: 1rem;
}
.footerMenu ul li::before{
    position: absolute;
    content: ">";
    left: 0;
    color: var(--white);
    font-family: var(--f1);
}
.footerContact h3, .footerContact h4, .footerContact p, .footerMenu ul li a, .footerMenu h3, .google p{
    color: var(--white);
    text-decoration: none;
}
.footerWrite{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;
    gap: 7rem;
}
.google{
    width: 100%;
}
.google p{
    padding-left: 1rem;
    margin-bottom: .25rem;
}
.google figure{
    width: 100%;
}
.google figure img{
    width: 100%;
}
.social{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: .5rem;
}
.social a{
    width: 20%;
}
.social img{
    width: 100%;
}

@media screen and (max-width:768px) {
    .footer{
        padding: 4rem 1.5rem;
        padding-bottom: .5rem;
        background-size: cover;
    }
    .footerMain, .footerContact, .footerMenu, .footerWrite{
        width: 100%;
        min-width: auto;
        margin-bottom: 1.5rem;
    }
    .footerContact p{
        text-align: left;
        padding:.5rem 1rem;
    }
    .footerWrite{
        gap: 1.5rem;
    }
    .footerWrite p{
        text-align: left;
        padding-bottom: 1rem;
    }
    .google figure{
        width: 50%;
    }
    .social{
        width: 80%;
    }
}