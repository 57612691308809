.homrHero{
    width: 100%;
    background-image: url("../../public/images/hero_bg_2_1.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding: 1rem;
    padding-bottom: 5rem;

    position: relative;
}
.homrHero main{
    width: 100%;
    padding-top: 2%;
    padding-left: 10%;
    padding-bottom: 5%;
    margin-top: 5%;
    margin-bottom: 4%;
    position: relative;
    z-index: 1;
}
.homrHero main h2{
    font-size: var(--s7);
    font-family: var(--f1);
    font-weight: var(--w4);
    color: var(--title);
    line-height: var(--s7);
    margin-bottom: 1rem;
}
.homrHero main h2 span{
    font-weight: var(--w1);
    color: var(--theme);
}
.heroContent{
    width: 45%;
}
.homrHero main aside{
    width: 40%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2rem;
    padding: 1rem;
    padding-left: 0;
}
.homeHeroImage{
    width: 50%;
    position: absolute;
    right: 0%;
    bottom: 0;
    min-width: 550px;
    margin-right: 1%;
    display: flex;
    align-items: flex-end;
    z-index: 0;
}
.homeHeroImage img{
    width: 100%;
}
.heroFloat1{
    position: absolute;
    top: 10%;
    left: 50%;
    transform: rotate(0deg);
    animation: shape1 6s infinite linear;
}
.heroFloat2{
    position: absolute;
    top: 30%;
    right: 10%;
    animation: shape2 7s infinite linear;
}
.heroFloat3{
    position: absolute;
    bottom: 5%;
    left: 2%;
    animation: shape1 6s infinite linear;
}
.heroFloat4{
    position: absolute;
    bottom: 10%;
    right: 50%;
    animation: shape3 6s infinite linear;    
    width: 40px;
    height: 100px;
    transform: rotate(60deg);
}
.heroFloat5{
    position: absolute;
    bottom: 10%;
    right: 10%;
    animation: shape1 6s infinite linear;
    width: 100px;
}
.heroFloat4 img{
    width: 100%;
}
.heroFloat5 img{
    width: 100%;
}

@keyframes shape1 {
    0%{
        transform: rotate(0deg);
    }
    50%{
        transform: rotate(180deg);
    }
    100%{
        transform: rotate(360deg);
    }
}
@keyframes shape2 {
    0%{
        top: 30%;
    }
    25%{
        top: 35%;
    }
    50%{
        top: 30%;
    }
    75%{
        top: 25%;
    }
    100%{
        top: 30%;
    }
}
@keyframes shape3 {
    0%{
        bottom: 10%;
    }
    25%{
        bottom: 15%;
    }
    50%{
        bottom: 10%;
    }
    75%{
        bottom: 5%;
    }
    100%{
        bottom: 10%;
    }
}
@keyframes shape5 {
    0%{
        bottom: 10%;
    }
    25%{
        bottom: 7%;
    }
    50%{
        bottom: 10%;
    }
    75%{
        bottom: 13%;
    }
    100%{
        bottom: 10%;
    }
}

@media screen and (max-width:768px) {
    .homrHero{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding-bottom: 0;
        margin-bottom: 5rem;
    }
    .homrHero main{
        padding-left: 0;        
        margin: 0;
        margin-top: 25%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
    .homrHero main h2{
        font-size: var(--s6);        
        line-height: var(--s6);
        text-align: center;
    }
    .heroContent{
        width: 100%;
        text-align: center;
    }
    .homrHero main aside{
        width: 100%;
        flex-direction: column;
    }
    .homeHeroImage{
        position: relative;
        width: 100%;
        min-width: auto;
    }
    .heroFloat1, .heroFloat2{
        width: 70px;
        animation: shape1 6s infinite linear;
    }    
    .heroFloat1 img, .heroFloat2 img{
        width: 100%;
    }
    .heroFloat1{
        top: 5%;
        left: 5%;
    }
    .heroFloat2{
        top: 5%;
        right: 5%;
    }
    .heroFloat4{
        display: none;
    }
}

/* ================ homeService ==================================================================== */
.homeServices{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 5%;
    padding-top: 10rem;
    padding-bottom: 7%;
}
.homeServices header{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.homeServices header h2{
    text-align: center;
}
.homeServices article{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 3rem;
    flex-wrap: wrap;
    margin-top: 5rem;
}

/* ================ Welcome ===========================================================================*/
.homeWelcome{
    width: 100%;
    padding-left: 10%;
    padding-top: 5%;
    padding-bottom: 5%;
    background-color: var(--theme2);   
    position: relative;
    margin-bottom: 5rem;
    margin-top: 7rem;
}
.homeWelcome main{
    width: 50%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.homeWelcome main h2{
    font-size: var(--s6);
    font-family: var(--f1);
    font-weight: var(--w3);
    color: var(--white);
    line-height: var(--s6);
    text-transform: capitalize;
    margin-bottom: 2rem;
}
.homeWelcome main p{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--white);
    line-height: var(--s4);
    margin-bottom: 2rem;
}
.homeWelcome main aside{
    width: 100%;
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
}
.homeWelcome figure{
    position: absolute;
    bottom: 0px;
    right: -5%;
    width: 50%;
    display: flex;
    align-items: flex-end;
}
.homeWelcome figure img{
    width: 100%;
}
@media screen and (max-width:768px) {
    .homeWelcome{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        padding: 1rem;
        padding-bottom: 0;
    }    
    .homeWelcome main{
        width: 100%;
    }
    .homeWelcome main h2{
        font-size: 36px;
        line-height: 40px;
        text-align: center;
    }
    .homeWelcome main p{
        text-align: center;
        line-height: var(--s5);
    }
    .homeWelcome main aside{
        flex-direction: column;
        padding: 2rem;
    }
    .homeWelcome figure{
        position: relative;
        width: 100%;
        align-items: center;
        right: 0;
    }
    .homeWelcome figure img{
        object-fit: contain;
    }
}
/* ================ Choose ==============================================*/
.homeChoose{
    width: 100%;
    padding: 5% 10%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5%;
}
.homeChoose main{
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.homeChoose main h2{
    font-size: var(--s6);
    font-family: var(--f1);
    font-weight: var(--w3);
    color: var(--title);
    line-height: var(--s6);
    text-transform: capitalize;
    margin-bottom: 2rem;
}
.homeChoose main aside{
    position: relative;
    padding-left: calc(1.5rem + var(--s5)/2);
    margin-left: calc(1rem + var(--s5)/2);
    border-left: 1px solid var(--theme);
    padding-bottom: 1.5rem;
}
.homeChoose main div aside:last-child { 
    border: none;
}
.homeChoose main aside:hover span{
    background-color: var(--theme);
    color: var(--white);
}
.homeChoose main aside span{
    border-radius: 50%;
    border: 1px solid var(--theme);
    font-size: var(--s5);
    font-family: var(--f2);
    font-weight: var(--w3);
    color: var(--theme);
    line-height: var(--s5);
    background-color: var(--white);
    transition: .75s all;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 50px;
    aspect-ratio: 1/1;
    top: 0;
    left: calc((1.5rem + var(--s5)) * -1/2);
}
.homeChoose main p{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--body);
    line-height: var(--s5);
    /* margin-top: 1rem; */
    padding-bottom: 1.5rem;
    border-bottom: 1px solid var(--light);
}
.homeChoose figure{
    width: 45%;
    border-radius: 30px;
    overflow: hidden;
}
.homeChoose figure img{
    width: 100%;
    object-fit: cover;
}
@media screen and (max-width:768px) {
    .homeChoose{
        flex-direction: column;
        align-items: center;
        padding: 5%;
        margin-bottom: 5rem;
    }
    .homeChoose main{
        width: 100%;
        align-items: center;
    }
    .homeChoose main h2{
        font-size: 36px;
        line-height: 40px;
        text-align: center;
    }
    .homeChoose main aside{
        padding-top: 1rem;
    }
    .homeChoose main aside h3{
        margin-bottom: 1rem;
    }
    .homeChoose figure{
        width: 90%;
    }
}
/* ====================== Counter ============================================== */
.homeCounter{
    width: 90%;
    background-color: var(--title);
    margin-left: 5%;
    border-radius: 25px;
    padding: 2rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.homeCounter div{
    width: calc(100% / 4);
    border-right: 2px solid var(--white);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
}
.homeCounter div:last-child {
    border: none;
}
.homeCounter div h2{
    font-size: var(--s6);
    font-family: var(--f1);
    font-weight: var(--w4);
    color: var(--theme);
    line-height: var(--s6);
    margin-bottom: 1rem;
}
.homeCounter div h2 span{
    color: var(--white);
    font-size: var(--s6);
    font-weight: var(--w2);
    margin-left: .5rem;
}
.homeCounter div p{
    font-size: var(--s2);
    font-family: var(--f2);
    font-weight: var(--w1);
    color: var(--body);
    line-height: var(--s2);
}
@media screen and (max-width:768px) {
    .homeCounter{
        flex-wrap: wrap;
        padding: 1rem;
        margin-bottom: 5rem;
    }
    .homeCounter div{
        width: calc(100% / 2);
        padding: 1rem;        
    }
    .homeCounter div:first-child{
        border-bottom: 2px solid var(--white);
    }
    .homeCounter div:nth-child(2){
        border-right: none;
        border-bottom: 2px solid var(--white);
    }
}
/* =============== Flow =========================================================== */
.homeFlow{
    width: 100%;
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.homeFlow h2{
    font-size: var(--s6);
    font-family: var(--f1);
    font-weight: var(--w3);
    color: var(--title);
    line-height: var(--s6);
    text-transform: capitalize;
    margin-bottom: 2rem;   
}
.homeFlow main{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 5%;
}
.homeFlow main div{
    width: 250px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.homeFlow main div aside{
    position: relative;
    width: 80%;
    aspect-ratio: 1/1;
    margin-bottom: 1.5rem;
}
.homeFlow main div figure{
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
}
.homeFlow main div figure img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: .5s all;
}
.homeFlow main div figure img:hover{
    transform: scale(1.2);
}
.homeFlow main div span{
    border-radius: 50%;
    font-size: var(--s5);
    font-family: var(--f2);
    font-weight: var(--w3);
    line-height: var(--s5);
    transition: .75s all;

    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    width: 50px;
    aspect-ratio: 1/1;
    top: 0;
    right: 0%;
}
.processTheme{
    border: 3px solid var(--theme);
}
.processThemeSpan{
    border: 1px solid var(--theme);
    color: var(--white);
    background-color: var(--theme);
}
.processTheme2{
    border: 3px solid var(--theme2);
}
.processTheme2Span{
    border: 1px solid var(--theme2);
    color: var(--white);
    background-color: var(--theme2);
}
.processContent{
    text-align: center;
}
@media screen and (max-width:768px) {
    .homeFlow h2{
        font-size: 36px;
        line-height: 40px;
        text-align: center;
    }
    .homeFlow main{
        flex-direction: column;
    }
    .homeFlow main div{
        width: 90%;
        margin-bottom: 2rem;
    }
    .homeFlow main div aside{
        width: 60%;
    }
}
