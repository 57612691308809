nav{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem 3rem;
    background-color: var(--vrts);
    gap: 2rem;
}
nav figure{
    width: 150px;
    position: relative;
    top: 0;
    left: 0;
    z-index: 15;
    margin-right: auto;
}
nav figure img{
    width: 100%;
    object-fit: contain;
}
nav ul{
    display: flex;
    list-style: none;
    gap: 1rem;
}
nav ul li a{
    color: var(--white) !important;
    text-decoration: none;
}
/* .menuIcon{
    display: flex;
    flex-direction: column;
    margin: 0;
    transition: .75s all;
    position: relative;
    display: flex;
    align-items: center;
    padding: 0;
} */
.menuIcon{
    width: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    position: static;
    right: 5rem; 
    cursor: pointer;
    z-index: 15;   
}
.bar{
    width: 100%;
    height: 3px;
    margin-bottom: 3px;
    background-color: var(--white);
    transition: all .5s ease-in-out;
    border-radius: 3px;
}
.bar1{
    transform: rotate(-135deg) translateX(-5px) translateY(-3.5px);
    background-color: var(--white);
}
.bar2{
    transform: translateX(500px);
    opacity: 0;
}
.bar3{
    transform: rotate(135deg) translateX(-5px) translateY(3.5px);
    background-color: var(--white);
}
nav section{
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    right: 0;
    z-index: 5;
    background-color: var(--vrts);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: .25s all linear;
}
nav section ul{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    padding: 3rem;
    white-space: nowrap;
}
.mobMenuShow{
    width: 100%;
    opacity: 1;
}
.mobMenuHide{
    width: -0;
    opacity: 0;
}
.mobPath::after{
    content: '';
    background-color: var(--white);
    justify-content: flex-end;
    width: 50%;
    padding: 4px;
    margin-left: .5rem;
}
.webPath::after{
    content: '';
    background-color: var(--white);
    display: flex;
    width: 100%;
    padding: 1px;
}
.menuContact{
    background-color: var(--white);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: .5rem 1rem;
    border-radius: 25px;
    gap: .5rem;
    text-decoration: none;
}
.menuContact img{
    width: 20px;
}
.menuContact p{
    font-size: var(--s2);
    font-family: var(--f1);
    font-weight: var(--w3);
    color: var(--title);
}
@media screen and (max-width:768px) {
    nav{
        padding: .5rem 1rem;
    }
}