.contactWrapper{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10rem;    
}
.contactWrapper h2{    
    width: 70%;
    text-align: center;
}
.contactLanding{    
    background-image: url("../../public/images/breadcumb-bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.contactBranches{
    padding: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;
}
@media screen and (max-width:768px) {
    .contactWrapper{
        padding: 10rem 1rem;
    }
}

.contactCard{
    width: 350px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 25px;
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    position: relative;
}
.contactLocation{
    text-align: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--light);
}
.contactDetails{
    text-decoration: none;
    display: flex;
    gap: .5rem;
}
.contactCard::after{
    content: '';
    height: 5px;
    position: absolute;
    top: 0;
    left: 25px;
    width: calc(100% - 50px);
    background-image: radial-gradient(50% 50% at 50% 50%, var(--location_theme) 56.77%, rgba(4, 206, 120, 0) 100%);

}